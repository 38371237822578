import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const Body = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  /*   background-color: green;
 */
  display: flex;
  flex-flow: column nowrap;
  padding: 7%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    grid-area: 2/ 2 / 3 / 3;
    padding-top: 0;
    overflow: scroll;
  }
`

const Title = styled.div`
  margin-top: 0;
  font: 700 1.5em Museo;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
  }
`

const Header = styled.h1`
  margin-top: 0;
  font: 700 2.3em Museo;
  margin-bottom: 8%;

  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    margin-bottom: 4%;
  }
`
const Article = styled.article`
  margin-bottom: 4%;
  display: grid;
  grid-template-columns: 25% auto;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
  }
`

const BookImg = styled.img`
  max-width: 80%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    height: 10vh;
    display: block;
    max-width: 100%;
  }
  @media only screen and (min-width: 1000px) {
    /* For everything bigger than 768px */
    height: 20vh;
    display: block;
  }
`

const BookContainer = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    display: block;
  }
`

const Date = styled.small`
  font: 300 0.85em Museo;
`
const Description = styled.p`
  font: 300 1.3em Museo;
`

export default () => {
  const data = useStaticQuery(graphql`
    query goodRead {
      goodreadsShelf {
        reviews {
          rating
          book {
            bookID
            isbn
            isbn13
            uri
            link
            title
            imageUrl
            smallImageUrl
            description
          }
        }
      }
    }
  `)
  const reviews = Object.entries(data.goodreadsShelf.reviews)
  const clean_arr = reviews.map(x => x[1])
  return (
    <Body>
      <Header>Some Books I Enjoyed</Header>
      {clean_arr.map(x => (
        <Article key={x.book.title}>
          <BookContainer>
            <BookImg src={x.book.imageUrl}></BookImg>
          </BookContainer>
          <div style={{ gridArea: "1 / 2 / 2 / 3" }}>
            <header>
              <Title>
                <a
                  style={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `inherit`,
                  }}
                  href={x.book.link}
                >
                  {x.book.title}
                </a>
              </Title>
              <Date>{x.book.title}</Date>
            </header>
            <section>
              <Description
                dangerouslySetInnerHTML={{
                  __html: x.book.description.slice(0, 300) + "...",
                }}
              />
            </section>
          </div>
        </Article>
      ))}
    </Body>
  )
}
